@import url('https://fonts.googleapis.com/css?family=Lato:400,800&display=swap');

$color: #351b51;
$color_claro1: #594474;
$color_claro2: #86779f;
$color_claro3: #d4baff;
$color_lila: #c24f97;
$color_amarillo: #f39325;
$color_naranja: #e63323;

*{
    font-family: 'Lato', sans-serif !important;
}

.page-link{
    color: $color;
    transition: all 300ms;
}
.page-link:hover, .page-link:active {
    color: $color_claro1;
}
.page-item.active .page-link {
    background-color: $color;
    border-color: $color;
}
.btn-primary {
    color: #fff;
    background-color: $color;
    border-color: $color;
}
.btn-primary:hover, .btn-primary:active {
    background-color: $color_claro1 !important;
    border-color: $color_claro1 !important;
}
.btn-secondary {
    background-color: #fff;
    color: $color;
    border-color: $color_claro3;
}
.btn-secondary:hover, .btn-secondary:active {
    background-color: #ececec !important;
    color: $color !important;
    border-color: $color !important;
}
.badge-secondary {
    background-color: $color_claro1;
}
.badge-secondary:hover {
    background-color: $color !important;
}

.sombra {
    box-shadow: 0 3px 17px 0 rgba(53, 27, 81, 0.2);
}
.sans {
    font-family: sans-serif;
}
.padsection1 {
    padding: 3;
}
.padsection2 {
    padding: 3;
}
.distribu {
    color:#8c98b4;
}
.jumbo {
    border-radius: 15px;
    padding: 0;
    border: 40px;
}
.imgtarget {
    overflow: hidden;
}
.asigna {
    background-color: #088A68;
    background-image: linear-gradient(45deg, $color, $color_lila);
    color: white;
    border-radius: 50px;
    border-style: none;
    height: 3em;
    position: absolute; bottom: 0px; right: 15px;
    padding: 0px 30px;
}
.leftpad {
    padding-left: 30px;
}

.pad20 {
    padding:50px;
}
.alto320 {
    height: 310px;
}
.alto320m {
    min-height: 310px;
}

.relleno{
    height: 30px;
}
.cientosesenta{
    height: 160px;
}
.ancho{
    width:100%;
    height: auto;
}
#nombre{
    color:#474A4B;
}
#tipoequipo{
    color:#8c98b4;
}


.textareadesc{
    width: 100%;
    border: 0px;
    box-shadow: 0px 2px 20px rgba(0,0,0,0.15);
    height: 85px;
    border-radius: 7px;
}
.comp h3{
    text-align:center
}
.iconeye{
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h3{
    margin-bottom: 0;
}

.iconplot {
    background-image: linear-gradient(45deg, $color 0%, $color_lila 100%);
    box-shadow: 0 10px 20px 0 rgba(74, 74, 74, 0.26);
    border-radius: 3px;
    max-width: 50px;
    width: 50px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radius {
    border-radius: 18px;
}

.table {
    // height:400px;
    width: 100%;
    margin-bottom: 0;
}

.rt-th {
    border: 0px !important;
    font-weight: 700;
    color: gray;
}

.bottonera {
    display: flex;
    flex-direction: row;
    border: 1px solid #DEE1E6;
    border-radius: 100px;
    height: 45px;
    width: auto;
    align-items: center;
    justify-content: space-evenly;
}

.boton {
    font-size: 12px;
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.buticon {
    cursor: pointer;
}

.buticon:hover {
    opacity: 0.8;
}

.activo {
    background-image: linear-gradient(90deg, $color 0%, $color_lila 100%);
    box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    padding: 10px 10px;
    color: white;
    font-weight: 700;
    transition: filter 300ms;
    cursor: default;
}

.activo:hover {
    filter: brightness(0.9) saturate(1.1)
}

.table {
    overflow: hidden;
    border-radius: 19px;
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
    height: 55px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ReactTable .rt-tbody .rt-tr-group {
    height: 65px;
}

.ReactTable .rt-td{
    display: flex;
    align-items: center;
}

.cabecera {
    display: flex;
    flex-direction: row;
    height: 75px;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid gainsboro;
    .comp {
        color: gainsboro;
        font-weight: 700;
        font-size: 16px;
    }
}

.megarow {
    padding-right:10px;
    height: 70px;
    border-bottom: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: space-evenly
}

.megarow:nth-child(even) {
    background-color: #F2F2F2;
}

.avatar {
    width: 50px !important;
    height: 50px !important;
}

.avatarbig {
    width: 70px !important;
    height: 70px !important;
}

.comp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 12px;
    overflow: hidden;
    text-align: center;
}

.meganum {
    font-size: 40px;
    font-weight: 700;
    color: gray;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.leftpad {
    p {
        margin-bottom: 0;
    }
}

.navbar-brand img {
    width: 150px;
    height: auto;
}

.nombreavatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h5 {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 24px;
    }
    p {
        margin-bottom: 0px;
    }
}

.Modal {
    border-radius: 4px;
    width: 80%;
    // height: 500px;
    background-color: white;
    z-index: 5;
    // overflow-Y: overlay;
    max-height: 95vh;
    overflow-y: auto;
}

.inputer, .inputer_conunit input {
    background: #FFFFFF;
    border-radius: 7px;
    height: 45px;
    width: 100%;
    border: 0px;
    padding: 0px 10px;
}

.inputer{
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
}

.inputer_conunit{
    display: flex;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
    border-radius: 7px;

}

.inputer_conunit .unit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    background-color: #eee;
    border-radius: 0 7px 7px 0;
}

input.inputer:focus {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.23);
}

.selecthor {
    background: #FFFFFF !important;
    box-shadow: 0 3px 17px 0 rgba(53, 27, 81, 0.2) !important;
    border-radius: 7px !important;
    min-height: 45px;
    &:not(.noheight){
        height: 45px !important;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    &>div{
        border-width: 0px;
        z-index: 1234;
    }
    &.disabled > div{
        height: 100% !important;
    }
}

.moradito__option--is-focused{
    background-color: $color_claro3 !important;
}
.moradito__option--is-selected{
    background-color: $color_claro2 !important;
    color: white !important;
}
.moradito__control, .moradito__control:hover, .moradito__control--menu-is-open{
    // border: none !important;
    box-shadow: none !important;
    // height: 45px;
}
.moradito__control--menu-is-open, .moradito__control--is-focused, .moradito__control:focus{
    border-color: $color_claro2 !important;
    box-shadow: 0 0 1px $color_claro2 !important;
}
.moradito__menu-list{
    padding: 0 !important;
    border-radius: 3px;
    overflow: hidden;
}


.css-1aya2g8 {
    height: 45px;
    border: 0px !important;
    
}

.css-1rtrksz {
    border: 0px !important;
    height: 45px !important;
}

.nobut {
    opacity: 0.58;
    background-image: linear-gradient(-90deg, #E5E5E5 0%, #939393 100%);
    box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.nobut:hover {
    opacity: 0.38;
}

.sibut {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    /* Rectangle Copy: */
    height: 50px;
    background-image: linear-gradient(-90deg, #C0D9B2 0%, #04685C 100%);
    box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.16);
    border-radius: 100px;
    color: white;
}

.sibut:hover {
    opacity: 0.88;
}

.transi{
    transition: all 300ms;
    cursor: pointer;
}

.pagination{
    cursor: pointer;
}
.act .page-link, .desact .page-link{
    color: #232323;
}
.page-item.active.desact .page-link {
    background-color: $color_naranja;
    border-color: $color_naranja;
    z-index: 0;
}
.page-item.active.act .page-link {
    background-color: #a5fd51;
    border-color: #a5fd51;
    z-index: 0;
}

.innercontainer{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-y: scroll;
}

.tarjetaequipo{
    display:flex;
    align-items:center;
    justify-items:center;
    padding-bottom:20px;
    overflow: hidden;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding-bottom:20px !important;
}
.persona{
    margin-top: 30px;
    max-height: 160px;
    max-width: 160px;
    border-radius: 100px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
}

.imagetarjetaequipo {
    flex: 4
}

.textotarjetaequipo {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    flex: 2;
}

.botonestarjetaequipo {
    flex: 1;
    display: flex;
    padding: 0px 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center
}

.centerall{
    display:flex;
    align-items:center;
    justify-content: center;
}

.subcard {
    height: 120px;
    background-color: white;
}
.subcard h4{
    font-size: 16px;
}

.barraabajo {
    background-color: $color_amarillo;
    height: 6px;
    width: 90%;
    position: absolute;
    bottom: 25px;
}

.height100 {
    height: 105px;
}

.centerleft {
    display: flex;
    padding-left:30px;
    justify-content: center;
}

.subcardtitle {
    color: white;
    margin-top: 10px;
    font-size: 12px;
}

.cajamision {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    border: 7px solid #199019;
    border-radius: 22px;
    .overlaybg {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 0;
        border-radius: 15px;
    }
    h2 {
        font-size: 40px;
        color: white;
        z-index: 1;
    }
    h4 {
        z-index: 1;
        color: white;
    }
}

.bignumber {
    height: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid gainsboro;
    text-align: center;
    h1 {
        font-size: 80px;
        font-weight: 600;
        padding-bottom: 0px;
        line-height: 70px;
    }
    p {
        color: gray;
        font-size: 12px;
        padding: 0px;
        margin: 0px;
    }
}

.bordefino {
    border: 1px solid gainsboro;
    padding: 0px;

    small {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid gainsboro;
    }
    h4 {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        font-weight: 600;
    }
}

.carditinerario {
    // height: 250px;
    background-size: cover;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    .overlay {
        background-color: rgba(80, 90, 20, 0.85);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 0;
    }
    h3 {
        z-index: 1;
        color: white;
        margin-bottom: 14px;
    }
    p {
        color: rgba(255, 255, 255, 0.85);
        z-index: 1;
    }
}

.carditinerario:hover {
    transform: scale(1.1);
    transition: all 0.3s;
    z-index: 2;
}

.carditinerario .active {
    .overlay {
        display: none;
    }
}

a:hover{
    text-decoration: none;
}

.persocarduser {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.bigtotalrank {
    // border-right:1px solid gainsboro; 
    border-bottom: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 200px;
}

.titlerank {
    height: 50px;
    border-bottom: 1px solid gainsboro;
}

.numberrank {
    font-size: 40px;
    font-weight: 500;
    height: 60px;
}

.borderradiusleft {
    border-left: 1px solid gainsboro;
}

.comp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    transition: all 300ms;
    .left {
        justify-content: left;
        text-align:left;
    }
    h3 {
        font-size: 16px;
    }
}

.finalizaActivas .rt-td{
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: normal;
}

.ql-editor{
    min-height: 135px;
    padding-bottom: 0;
}

.form-control:focus{
    box-shadow: 0 0 0 0.2rem rgba(140, 109, 181, 0.45);
    border-color: $color_claro2;
}

.rt-table select{
    border:0px;
    outline:0px;
}